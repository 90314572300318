import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { yearLawyer } from "../../redux/actions/lawyer_actions";
import Loader from "../../common/loader/Loader";
import NoContent from "../../common/shared/NoContent";
import LawyerCard from "./LawyerCard";
import PreviousButton from "../../common/buttons/PreviousButton";
import NextButton from "../../common/buttons/NextButton";

function LawyerYears() {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const { yearData } = useSelector((state) => state.lawyer);

  const fetchLawyers = (pageNumber) => {
    const values = {
      id,
      page: pageNumber,
    };
    setLoading(true);
    setPage(pageNumber);
    dispatch(yearLawyer(values)).finally(() => setLoading(false));
  };

  const handleNextPage = () => {
    if (page < yearData.totalPages) {
      const newPage = page + 1;
      setPage(newPage);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      const newPage = page - 1;
      setPage(newPage);
    }
  };

  useEffect(() => fetchLawyers(page), [page]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex items-center shadow-xl px-3 md:px-10 lg:15 xl:px-20 banner">
            <div className="flex w-full flex-col md:flex-row justify-between gap-2 items-center">
              <h2 className="text-[2rem] md:text-[3rem] text-white font-bold">
                {yearData.count} Lawyers Called in {id}
              </h2>
            </div>
          </div>

          <div className="grid grid-cols-1 static top-0 lg:gap-8 px-3 md:px-10 lg:15 xl:px-20">
            <div className="py-5 lg:col-span-3 xl:col-span-4 grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 gap-3 lg:gap-8">
              {yearData.count === 0 ? (
                <NoContent message="No data to display" />
              ) : (
                yearData.users.map((item, index) => (
                  <LawyerCard key={index} data={item} />
                ))
              )}
            </div>
          </div>

          <div className="py-12 flex justify-center gap-4">
            <PreviousButton onClick={handlePreviousPage} page={page} />
            <NextButton
              onClick={handleNextPage}
              page={page}
              total={yearData.totalPages}
            />
          </div>
        </>
      )}
    </>
  );
}

export default LawyerYears;
