import { Button, Card, CardBody, CardFooter, Typography } from "@material-tailwind/react";
import React, { useState } from "react";
import InputText from "../../common/form/InputText";
import { LAWYER_FILTER_DATA } from "../../constants/constants";
import SelectOption from "../../common/form/SelectOption";
import regions from "../../utils/regions";
import practice_areas from "../../utils/practice_areas";
import gender from "../../utils/gender";
import { useDispatch } from "react-redux";
import { filterLawyer } from "../../redux/actions/lawyer_actions";
import { getYearsFrom1960 } from "../../utils/listYears";
import { Slider, InputNumber } from "antd";

import { IoSearchCircleSharp } from "react-icons/io5";

function LawyerFilter() {
    const [formData, setFormData] = useState(LAWYER_FILTER_DATA);
    const [filterLoading, setFilterLoading] = useState(false);

    const dispatch = useDispatch();

    const years = getYearsFrom1960();
    const lastYear = parseInt(years[years.length - 1].id);
    const currentYear = parseInt(years[0].id);

    const updateFormValue = ({ updateType, value }) => {
        setFormData({ ...formData, [updateType]: value });
    };

    const onChange = (value) => {
        setFormData({ ...formData, start_date: value[0], end_date: value[1] });
    };

    const onStartDateChange = (value) => {
        setFormData({ ...formData, start_date: value });
        console.log(value);
    };

    const onEndDateChange = (value) => {
        setFormData({ ...formData, end_date: value });
        console.log(value);
    };

    const handleRefresh = () => {
        window.location.reload();
    };

    const handleFilter = () => {
        setFilterLoading(true);

        // const regionId = formData.region;
        // const practiceAreaId = formData.practice_area;

        // console.log("<-----hi----->", { regionId, practiceAreaId });
        // const year = parseInt(formData.year);

        // const parsedRegionId = Number.isNaN(regionId) ? "" : regionId;
        // const parsedPracticeAreaId = Number.isNaN(practiceAreaId)
        //   ? ""
        //   : practiceAreaId;
        // const parsedYear = Number.isNaN(year) ? "" : year;

        // const links = `?name=${
        //   formData.name
        // }&regionId=${parsedRegionId}&practiceAreaId=${parsedPracticeAreaId}&gender=${
        //   formData.gender
        // }&start_date=${
        //   formData.start_date ? formData.start_date : lastYear
        // }&end_date=${formData.end_date ? formData.end_date : currentYear}`;

        let link = "?";
        if (formData.name !== "") link += `name=${formData.name}&`;
        if (formData.region !== "") link += `regionId=${parseInt(formData.region)}&`;
        if (formData.practice_area !== "")
            link += `practiceAreaId=${parseInt(formData.practice_area)}&`;
        if (formData.gender !== "") link += `gender=${formData.gender}&`;
        link += `start_date=${formData.start_date ? formData.start_date : lastYear}&`;
        link += `end_date=${formData.end_date ? formData.end_date : currentYear}`;

        localStorage.setItem("lawyer", link);

        dispatch(filterLawyer(link)).finally(() => setFilterLoading(false));
    };

    return (
        <div className="pt-5 sticky top-0 xl:block h-fit">
            <Card>
                <CardBody className="p-0 pt-4">
                    <div className="flex items-center px-5 justify-between">
                        <Typography variant="h5" color="blue-gray" className="mb-2">
                            Filter Search
                        </Typography>

                        <Button onClick={handleRefresh} variant="text" color="pink">
                            Clear All
                        </Button>
                    </div>

                    <div className="p-5 flex flex-col gap-8">
                        <div className=" relative">
                            <InputText
                                defaultValue={formData.name}
                                updateType="name"
                                labelTitle="Name"
                                updateFormValue={updateFormValue}
                            />

                            <div className=" absolute  flex justify-end  items-center  right-0  top-0 h-full ">
                                <div
                                    onClick={() => handleFilter()}
                                    className=" flex justify-center items-center  cursor-pointer w-[40px] mr-[2px] h-[90%]  rounded-r-md bg-red-200 "
                                >
                                    <IoSearchCircleSharp size={25} className="text-[#E91E63]" />
                                </div>
                            </div>
                        </div>

                        <SelectOption
                            labelTtile="Select Region"
                            defaultValue={formData.region}
                            updateFormValue={updateFormValue}
                            updateType="region"
                            children={regions}
                        />

                        <SelectOption
                            labelTtile="Practice Area"
                            defaultValue={formData.practice_area}
                            updateFormValue={updateFormValue}
                            updateType="practice_area"
                            children={practice_areas}
                        />

                        <SelectOption
                            labelTtile="Select Gender"
                            defaultValue={formData.gender}
                            updateFormValue={updateFormValue}
                            updateType="gender"
                            children={gender}
                        />

                        <div className="flex flex-col">
                            <span>Select Year of Call</span>
                            <Slider
                                range={{ draggableTrack: true }}
                                min={lastYear}
                                max={currentYear}
                                onChange={onChange}
                                value={[formData.start_date, formData.end_date]}
                            />
                            <Typography variant="small" className="mt-4">
                                Drag to select year of call
                            </Typography>

                            <div className=" w-full flex justify-between mt-5 ">
                                <div>
                                    <InputNumber
                                        size="large"
                                        min={lastYear}
                                        max={currentYear}
                                        name="start_date"
                                        value={formData.start_date}
                                        onChange={onStartDateChange}
                                    />
                                    <Typography variant="small" className="mt-2">
                                        Year From
                                    </Typography>
                                </div>

                                <div>
                                    <InputNumber
                                        size="large"
                                        min={lastYear}
                                        max={currentYear}
                                        name="end_date"
                                        value={formData.end_date}
                                        onChange={onEndDateChange}
                                    />
                                    <Typography variant="small" className="mt-2">
                                        Year To
                                    </Typography>
                                </div>
                            </div>
                        </div>

                        {/* <SelectOption
              labelTtile="Select Year of Call"
              defaultValue={formData.year}
              updateFormValue={updateFormValue}
              updateType="year"
              children={years}
            /> */}
                    </div>
                </CardBody>
                <CardFooter>
                    <Button
                        onClick={() => handleFilter()}
                        color="pink"
                        fullWidth
                        loading={filterLoading}
                    >
                        Search
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
}

export default LawyerFilter;
