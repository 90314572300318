import { Typography } from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";
import { IoLocation } from "react-icons/io5";
import { MdOutlineGavel } from "react-icons/md";
import { IoCalendarOutline } from "react-icons/io5";
import moment from "moment";

function LawyerCard({ data }) {
  return (
    <div
      className="border h-fit w-full border-solid bg-white border-gray-200  p-4 hover:border-purple-200  rounded-lg
                   lg:hover:scale-105 ease-in-out duration-75 transition-all  cursor-pointer hover:shadow-md transparent shadow-lg text-gray-700"
    >
      <div className="h-500">
        <Link to={`/lawyers/${data.bar_number}`}>
          <Typography
            variant="h4"
            color="black"
            className="overflow-hidden line-clamp-2 h-[65px]"
          >
            {data.title + " " + data.name}
          </Typography>
        </Link>
      </div>
      <div className="text-sm text-gray-00 flex flex-col gap-2 mt-3 ">
        <div className="flex items-center gap-2">
          <div>
            <MdOutlineGavel className="text-[#F7443D]" />
          </div>
          <Link
            to={
              data?.chamber?.[0]?.name
                ? `/chambers/${data?.chamber?.[0]?.id}`
                : null
            }
          >
            <Typography className=" text-[#F7443D]">
              {data?.chamber?.[0]?.name ?? "N/A"}
            </Typography>
          </Link>
        </div>
        <div className="flex items-center gap-2">
          <div>
            <IoLocation className="text-[#F7443D]" />
          </div>
          <Typography className="truncate">
            {data?.region ?? "N/A"} Region
          </Typography>
        </div>
        <div className="flex items-center gap-2">
          <div>
            <IoCalendarOutline className="text-[#F7443D]" />
          </div>
          <Link to={`/lawyers/year/${moment(data.doc).format("YYYY")}`}>
            <Typography className="truncate">
              {moment(data.doc).format("YYYY")}
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LawyerCard;
