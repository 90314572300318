import { Suspense, useEffect } from "react";
import FallbackLoading from "./common/loader/FallbackLoading";
import { Route, Routes } from "react-router-dom";
import { publicRoutes } from "./routes/public.routes";
import TopNavbar from "./common/shared/TopNavbar";
import Cookies from "js-cookie";

function App() {
  // window.addEventListener(
  //   "message",
  //   (event) => {
  //     if (event.origin !== "https://svbredte545fx.ghanabar.org/") return;

  //     const receivedData = JSON.parse(event.data);
  //     console.log(receivedData);
  //   },
  //   false
  // );

  useEffect(() => {
    const message = Cookies.get("sharedData");
    if (message) {
      console.log("<-------message------>", message);
      const data = JSON.parse(message);
      localStorage.setItem("profile", data);
    }
    // const handleMessage = (event) => {
    //   console.log("<--------event-------->", event);
    //   if (event.origin !== "https://svbredte545fx.ghanabar.org") return;

    //   const { key, value } = event.data;
    //   localStorage.setItem(key, value);
    // };

    // window.addEventListener("message", handleMessage);
    // return () => window.removeEventListener("message", handleMessage);
  }, []);

  return (
    <div className="bg-[#EFEFF1]">
      <TopNavbar />
      <Suspense fallback={<FallbackLoading />}>
        <Routes>
          {publicRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
