import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { contactLawyer, singleLawyer } from "../../redux/actions/lawyer_actions";
import Loader from "../../common/loader/Loader";
import { LAWYER_MESSAGE_DATA } from "../../constants/constants";
import InputText from "../../common/form/InputText";
import InputArea from "../../common/form/InputArea";
import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Typography,
} from "@material-tailwind/react";
import DetailItem from "../../common/shared/DetailItem";
import moment from "moment";
import CorrectSVG from "../../common/shared/CorrectSVG";
import arrSeparator from "../../utils/arrSeparator";
import Cookies from "js-cookie";

function SingleLawyer() {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [formData, setFormData] = useState(LAWYER_MESSAGE_DATA);
    const [sendLoading, setSendLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [userData, setUserData] = useState(null);

    const dispatch = useDispatch();
    const { single, lawyerError } = useSelector((state) => state.lawyer);

    const userCookie = Cookies.get("dataCookie");
    // const userData = JSON.parse(userCookie);

    if (userCookie) {
        const expiryDate = new Date(Cookies.get("dataCookie").expires);
        if (expiryDate > new Date()) {
            const parsedData = JSON.parse(userCookie);
            setUserData(parsedData);
        } else {
            setUserData(null);
        }
    }

    const updateFormValue = ({ updateType, value }) => {
        setFormData({ ...formData, [updateType]: value });
    };

    const handleOpen = () => setOpen(!open);

    const handleSendMessage = (e) => {
        e.preventDefault();
        setSendLoading(true);
        const sendData = {
            id: single.email,
            first_name: formData.first_name,
            last_name: formData.last_name,
            message: formData.message,
            email: formData.email,
        };
        dispatch(contactLawyer(sendData)).finally(() => {
            setSendLoading(false);
            setOpen(true);
            setFormData(LAWYER_MESSAGE_DATA);
        });
    };

    useEffect(() => {
        dispatch(singleLawyer(id)).finally(() => setLoading(false));
    }, [dispatch, id]);

    useEffect(() => {
        if (lawyerError) {
            setErrorMessage(lawyerError);
        }
    }, [lawyerError]);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className="bg-[#EFEFF1] h-screen">
                    <div className="flex items-center shadow-xl px-3 md:px-10 lg:15 xl:px-20 banner">
                        <h2 className="px-2 md:px-10 lg:15 xl:px-20 text-[2rem] md:text-[3rem] text-white font-bold">
                            {single?.title + " " + single?.name}
                        </h2>
                    </div>

                    <div className="px-2 pt-10 md:px-10 lg:15 xl:px-20 bg-[#EFEFF1]  py-10">
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-20 justify-between w-full">
                            <div className="lg:col-span-2 flex flex-col gap-3">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
                                    <div className={`flex flex-col flex-1 mb-5`}>
                                        <Typography
                                            color="black"
                                            variant="paragraph"
                                            className="text-left w-full"
                                        >
                                            <span className="uppercase font-bold text-sm detail-title">
                                                Chamber Name
                                            </span>
                                        </Typography>
                                        <div className="flex flex-col">
                                            {single?.chamber.map((item) => (
                                                <Typography
                                                    key={item}
                                                    color="black"
                                                    variant="paragraph"
                                                    className="text-left text-xl w-full"
                                                >
                                                    {item}
                                                </Typography>
                                            ))}
                                        </div>
                                    </div>

                                    <DetailItem title="Region" item={single?.region} />
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
                                    {(userData === null
                                        ? single?.settings?.public?.bar_number || !single.settings
                                        : userData?.user?.isGLC
                                        ? single?.settings?.GLC?.bar_number
                                        : null) && (
                                        <DetailItem title="Bar Number" item={single?.bar_number} />
                                    )}

                                    {(userData === null
                                        ? single?.settings?.public?.enrolment_date ||
                                          !single.settings
                                        : userData?.user?.isGLC
                                        ? single?.settings?.GLC?.enrolment_date
                                        : null) && (
                                        <DetailItem
                                            title="Date Of Call"
                                            item={moment(single?.doc).format("DD/MM/YYYY")}
                                        />
                                    )}
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
                                    {userData?.user?.isGLC && (
                                        <DetailItem
                                            title="Date of birth"
                                            item={moment(single?.dob).format("DD/MM/YYYY")}
                                        />
                                    )}

                                    {(userData === null
                                        ? single?.settings?.public?.gender || !single.settings
                                        : userData?.user?.isGLC
                                        ? single?.settings?.GLC?.gender
                                        : null) && (
                                        <DetailItem
                                            title="Gender"
                                            item={single?.gender.toUpperCase()}
                                        />
                                    )}
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
                                    {(userData === null
                                        ? single?.settings?.public?.email || !single.settings
                                        : userData?.user?.isGLC
                                        ? single?.settings?.GLC?.email
                                        : null) && (
                                        <DetailItem title="Email Address" item={single?.email} />
                                    )}

                                    {(userData === null
                                        ? single?.settings?.public?.phone || !single.settings
                                        : userData?.user?.isGLC
                                        ? single?.settings?.GLC?.phone
                                        : null) && (
                                        <DetailItem title="Phone" item={single?.phone} />
                                    )}
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
                                    {(userData === null
                                        ? single?.settings?.public?.academic_q || !single.settings
                                        : userData?.user?.isGLC
                                        ? single?.settings?.GLC?.academic_q
                                        : null) && (
                                        <DetailItem
                                            title="Academic Qualifications"
                                            item={single?.qua}
                                        />
                                    )}
                                </div>

                                {(userData === null
                                    ? single?.settings?.public?.practice_areas || !single.settings
                                    : userData?.user?.isGLC
                                    ? single?.settings?.GLC?.practice_areas
                                    : null) && (
                                    <DetailItem
                                        title="Practice Areas"
                                        item={arrSeparator({ arr: single?.practice })}
                                    />
                                )}
                            </div>

                            <form onSubmit={(e) => handleSendMessage(e)}>
                                <div className="p-8 bg-white  h-fit shadow-lg rounded-md">
                                    <Typography variant="h4" className="mb-4">
                                        Get In Touch
                                    </Typography>
                                    <div className=" flex flex-col justify-between gap-6 w-full">
                                        <InputText
                                            defaultValue={formData.first_name}
                                            updateType="first_name"
                                            labelTitle="First Name"
                                            updateFormValue={updateFormValue}
                                            required={true}
                                        />

                                        <InputText
                                            defaultValue={formData.last_name}
                                            updateType="last_name"
                                            labelTitle="Last Name"
                                            updateFormValue={updateFormValue}
                                            required={true}
                                        />

                                        <InputText
                                            defaultValue={formData.email}
                                            updateType="email"
                                            labelTitle="Email Address"
                                            updateFormValue={updateFormValue}
                                            required={true}
                                        />

                                        <InputArea
                                            defaultValue={formData.message}
                                            updateType="message"
                                            labelTitle="Message"
                                            updateFormValue={updateFormValue}
                                            required={true}
                                        />
                                    </div>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        color="pink"
                                        className="mt-6"
                                        loading={sendLoading}
                                    >
                                        Send Message
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {open && (
                <Dialog open={open} handler={handleOpen}>
                    <DialogHeader className="grid place-items-center gap-4">
                        <Typography variant="h5" color="blue-gray">
                            Message Sent Successfully
                        </Typography>
                    </DialogHeader>
                    <DialogBody className="grid place-items-center gap-4">
                        <CorrectSVG />
                    </DialogBody>
                    <DialogFooter>
                        <Button variant="text" color="blue-gray" onClick={handleOpen}>
                            close
                        </Button>
                    </DialogFooter>
                </Dialog>
            )}
        </>
    );
}

export default SingleLawyer;
