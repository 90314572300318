import * as types from "../constants/lawyer_contstants";

const initialstate = {
  data: null,
  filter: null,
  single: null,
  lawyerError: null,
  message: null,
  yearData: null,
};

const lawyerReducer = (state = initialstate, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.ALL_LAWYERS_SUCCESS:
      return { ...state, data: payload ? payload : null };

    case types.SINGLE_LAWYER_SUCCESS:
      return { ...state, single: payload ? payload.user : null };

    case types.YEAR_LAWYER_SUCCESS:
      return { ...state, yearData: payload ? payload : null };

    case types.FILTER_LAWYER_SUCCESS:
      return { ...state, filter: payload ? payload : null };

    case types.CONTACT_LAWYER_SUCCESS:
      return { ...state, message: payload ? payload : null };

    case types.ALL_LAWYERS_FAILURE:
    case types.SINGLE_LAWYER_FAILURE:
    case types.FILTER_LAWYER_FAILURE:
      return { ...state, lawyerError: payload ? payload : null };

    default:
      return state;
  }
};

export default lawyerReducer;
